import React, { Component } from "react";
import window from 'global';
import '../styles/share.scss';
import ReactDOMServer from 'react-dom/server';
//import PropTypes from 'prop-types';
import Helmet from "react-helmet";
import Paper from '@material-ui/core/Paper';
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';
import '../styles/ProdTemplate.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import 'react-image-picker/dist/index.css'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var webURL = "https://dev.sales-app.cmps.emfluence.com";

function pdfOpen(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  } else {
    /*const pdfViewers = document.getElementsByClassName('pdfViewer');
    Array.prototype.forEach.call(pdfViewers, function(pdfAll) {
      pdfAll.classList.remove('active');
    });*/
    pdfViewer.classList.add('active');
  }
}
function pdfClose(e) {
  const pdfViewer = document.getElementById(e.currentTarget.value);
  if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
  }
}

class Pdfviewer extends Component {
  state = { numPages: null, pageNumber: 1, scaleControl: .75};

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  zoomIn = () =>
    this.setState(state => ({ scaleControl: state.scaleControl + .5 }));
  zoomOut = () =>
    this.setState(state => ({ scaleControl: state.scaleControl - .5 }));
  zoomReset = () =>
    this.setState(state => ({ scaleControl: 1 }));
  render() {
    const { pageNumber, numPages, scaleControl } = this.state;
    var intFrameHeight = window.innerHeight;
    var intFrameWidth = window.innerWidth;
    if (intFrameWidth > intFrameHeight) {
      intFrameWidth = intFrameHeight / 1.2 - 75;
    }
    if (intFrameHeight > intFrameWidth) {
      intFrameHeight = intFrameWidth / 1.1 - 75;
      intFrameWidth = intFrameWidth / 1.1 - 75;
    }
    return (
      <div id={this.props.id} className={this.props.className}>
        
        <button value={this.props.id} className="pdfClose" onClick={pdfClose}></button>
        <button value={this.props.id} className="pdfCloseX" onClick={pdfClose}></button>
        <div className="pdfBox">
          <Document
            file={this.props.file}
            onLoadSuccess={this.onDocumentLoadSuccess}
            height={intFrameHeight}
          >
            <Page pageNumber={pageNumber} scale={scaleControl} width={intFrameWidth}/>
          </Document>
        </div>
        <nav>
          {pageNumber > 1 &&
            <button className="prevArrow" onClick={this.goToPrevPage}></button>
          }
          {pageNumber <= 1 &&
            <button className="prevArrow disabled"></button>
          }
          <div className="pageNumbers">{pageNumber}/{numPages}</div>
          {pageNumber < numPages &&
            <button className="nextArrow" onClick={this.goToNextPage}></button>
          }
          {pageNumber >= numPages &&
            <button className="disabled nextArrow"></button>
          }
          {scaleControl > .5 &&
            <button className="zoomIcon" onClick={this.zoomOut}><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= .5 &&
            <button className="zoomIcon disabled"><Icon>zoom_out</Icon></button>
          }
          {scaleControl <= 3 &&
            <button className="zoomIcon" onClick={this.zoomIn}><Icon>zoom_in</Icon></button>
          }
          {scaleControl > 3 &&
            <button className="zoomIcon disabled"><Icon>zoom_in</Icon></button>
          }
        </nav>
      </div>
    );
  }
}

function openShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  var shareTitle = e.currentTarget.title;
  var shareFile = e.currentTarget.getAttribute('data-file');
  e.preventDefault();
  //shareDialog.classList.add('is-open');
  console.log('The link was clicked.');
  if (navigator.share) {
      navigator.share({
        title: shareTitle,
        url: shareFile
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      shareDialog.classList.add('is-open');
    }
}

function closeShareDialog(e) {
  var shareDialog = document.getElementById(e.currentTarget.value);
  e.preventDefault();
  shareDialog.classList.remove('is-open');
  console.log('The link was clicked.');
}


function videoPopup() {
  var pModal = document.getElementById('popupVideo');
  pModal.classList.add('active');
}
function closePModal() {
  var pModal = document.getElementById('popupVideo');
  pModal.classList.remove('active');
  const galleryVideo = document.getElementsByClassName('galleryVideo');
  for (var i=0, len=galleryVideo.length|0; i<len; i=i+1|0) {
      galleryVideo[i].pause();
  }
  
}

function saveAsPDF(content) {
  // fake server request, getting the file url as response
  var dloFile = content.currentTarget.getAttribute('data-file');
  if (window.matchMedia('(display-mode: standalone)').matches)  {
    var windowReference = window.open();
    windowReference.location = dloFile;
  }
  else {
    window.location.href = dloFile;
  }
}

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Helmet>
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <body className="company" />
    </Helmet>
    <header className="mainHead flexContainer faCenter fjCenter" css={css`
       background: #000;
    `}>
    <div className="fCol12 menuCtrl">
      <Link className="headerTitle" to={`/`}>
      
      <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide">Home</span>
      </Link>

      {/* Nav Side */}

      <Navlinks />
    </div>
    </header>
    <div id="tabsContainer" className="tabsContainer" css={css`margin-top:5vh;`}>
      <section id="content-resources" className="content-resources active">
        <div className="fCol12 brandName" css={css` color: #00ACED; margin-top:8vh!important; `}>
            <div className="subHeading">
              <h1>App Resources</h1>
            </div>
        </div>
        {data.wordpressPage.acf.app_resource != null && data.wordpressPage.acf.app_resource.map((resource, index) => (
          <div>
            <React.Fragment>
             
              {resource.resource_isvideo === false && 
                <React.Fragment>
                  <div className="grayBorderBottom">
                    <div className="flexContainer maxTablet">
                      <div className="fCol12 flexContainer faCenter">
                        <div className="dIcon">
                          {resource.app_resource_upload != null &&
                            <button value={'pdfViewer'+index} /*cUrl={resource.prod_upload_collateral.url.localFile.publicURL}*/ onClick={ pdfOpen }>
                              <Img className=""
                                sizes={resource.app_resource_thumbnail.localFile.childImageSharp.sizes}
                              />
                              VIEW        
                            </button>
                          }
                        </div>
                        <div className='dName' dangerouslySetInnerHTML={{ __html: resource.app_resource_name_1+'<br/>'+resource.app_resource_name_2}}></div>
                        <div className='dButtons'>
                          <Link>
                            <button value={'shareDialog'+index} id={'deviceShare'+index} type="button" className="grayButton" title={'Share '+resource.app_resource_name_1} data-file={webURL+resource.app_resource_upload.url.localFile.localURL} onClick={openShareDialog}>Share</button>            
                          </Link>
                          {resource.app_resource_zip_download != null &&
                            <a onClick={saveAsPDF} data-file={resource.app_resource_zip_download.url.localFile.localURL}><button  className="grayButton">Download</button></a>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <Pdfviewer id={'pdfViewer'+index} className="pdfViewer" file={resource.app_resource_upload.url.localFile.publicURL}/>
                  <div id={'shareDialog'+index} className="share-dialog">
                    <button value={'shareDialog'+index} onClick={closeShareDialog} className="boxClose"></button>
                    <div className="inner">
                      <header>
                        <h3 className="dialog-title">Share {resource.app_resource_name_1}</h3>
                      </header>
                      <button value={'shareDialog'+index} className="close-button" onClick={closeShareDialog}></button>
                      <div className="targets">
                        <a className="button" href={'mailto:?body='+webURL+resource.app_resource_upload.url.localFile.publicURL}>
                          <svg>
                            <use href="#email"></use>
                          </svg>
                          <span>Email</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
  
            </React.Fragment>    
          </div>
        ))}
        </section>
    </div>
      
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      parent_element {
        title
        slug
      }
      acf {
        app_resource {
          resource_isvideo
          app_resource_name_1
          app_resource_name_2
          app_resource_order
          app_resource_upload {
            url {
              localFile {
                localURL
                publicURL
              }
            }
          }

          app_resource_thumbnail {
            localFile {
              publicURL
              childImageSharp {
                  sizes(maxWidth:1200) {
                      ...GatsbyImageSharpSizes
                  }
              }
            }
          }
          app_resource_zip_download {
            url {
              localFile {
                localURL
                publicURL
              }
            }
          }
        }
      }
    }
  }
`